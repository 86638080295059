<template>
  <v-dialog
    :persistent="true"
    v-model="active"
    @keydown.esc="emitClose"
    width="auto"
    max-width="700px"
    class="overflow-overlay"
  >
    <v-card class="pa-0 rounded-lg">
      <v-card-text class="pa-0">
        <div style="position: relative; padding-bottom: 56.25%; height: 0;">
          <iframe
            src="https://www.loom.com/embed/5deb0f01d4c748d7b00f0804c6d98002"
            frameborder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
          ></iframe></div
      ></v-card-text>

      <v-card-actions class="d-flex justify-space-around py-6 mt-8" fluid>
        <v-btn color="darkprimary" rounded class="" @click="emitClose">
          {{ $t("Cerrar") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "HelpVideoDialog",
  components: {},
  props: ["active"],
  computed: {},
  methods: {
    emitClose() {
      this.$emit("close");
    }
  }
};
</script>
